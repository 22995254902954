<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col
          v-for="(item, index) in orderData"
          :key="index"
          cols="12"
          sm="6"
          md="3"
        >
          <base-card :class="item.background">
            <v-card-text class="d-flex align-center h-full">
              <div class="d-flex flex-grow-1 justify-space-between align-center flex-wrap">
                <div>
                  <p class="ma-0 white--text">
                    {{ item.heading }}
                  </p>
                  <div class="text-h4 white--text mt-2">
                    {{ item.value }}
                  </div>
                </div>
                <v-icon :class="item.iconColor" class="text-h3">
                  {{ item.icon }}
                </v-icon>
              </div>
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="6">
      <base-card>
        <v-card-title>
          {{ $t("dashboard.globalSalesByNetwork") }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-data-table
            :headers="compareHeaders"
            :items="compareData"
            hide-default-footer
            item-key="id"
            class="eg-table-bnone eg-table-td-py-md"
          >
            <template v-slot:item.count="{item}">
              {{ item.count ? item.count : '-' }}
            </template>

            <template v-slot:item.percent="{item}">
              <template v-if="item.percent === '0.00'">
                <span>-</span>
              </template>
              <template v-else>
                <p
                  :class="`${item.color}--text`"
                  class="caption font-weight-medium ma-0 text-right"
                >
                  {{ item.percent }}%
                </p>
                <v-progress-linear
                  :value="Number(item.percent)"
                  rounded
                  :color="item.color"
                />
              </template>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>

    <v-col cols="12" md="6">
      <base-card style="height:100%">
        <v-card-title>
          {{ $t('dashboard.manualQuotes') }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <apexchart
            v-if="totalPriceCount"
            type="pie"
            height="350"
            width="100%"
            :options="chartOptions"
            :series="manualQuotesChartData"
          />
          <div
            v-else
            class="text-center text-h5 error--text font-weight-bold mt-15"
          >
            {{ $t('common.noData') }}
          </div>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  const OrderStatusEnum = Object.freeze({
    REQUEST: 0,
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
    DELIVERED: 4,
  })

  export default {
    metaInfo: {
      title: 'Dashboard',
    },

    data() {
      return {
        OrderStatusEnum: OrderStatusEnum,
        orderData: [],
        compareData: [],
        pendingPriceData: null,
        approvedPriceData: null,
        totalPriceCount: 0,
        manualQuotesChartData: [0, 0],
        compareHeaders: [{
          text: this.$t('dashboard.category'),
          value: 'category'
        }, {
          text: this.$t('dashboard.count'),
          value: 'count'
        }, {
          text: this.$t('dashboard.percent'),
          value: 'percent'
        }],
        chartOptions: {
          legend: {
            position: 'bottom',
          },
          labels: ['Pending', 'Approved'],
          colors: ['#ff5252', '#5e5ce6'],
          grid: {
            padding: {
              top: 20,
              right: 0,
              bottom: 20,
              left: 0
            },
          },
        },
      }
    },

    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        isPartnerAdminUser: 'isPartnerAdminUser',
      }),
    },

    mounted() {
      this.initOrderCardText()
      this.getOrderStatisticsData()
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        getOrderStatistics: 'getOrderStatistics',
      }),

      initOrderCardText() {
        this.orderData = [{
          background: 'primary',
          icon: 'mdi-finance',
          iconColor: 'primary--text text--lighten-2',
          heading: `${this.$t('order.total')} ${this.$t('order.orders')}`,
          value: 0,
        }, {
          background: 'warning',
          icon: 'mdi-cached',
          iconColor: 'warning--text text--lighten-2',
          heading: this.$t('order.pending'),
          value: 0,
        }, {
          background: 'success',
          icon: 'mdi-cart-check',
          iconColor: 'success--text text--lighten-2',
          heading: this.$t('order.approved'),
          value: 0,
        }, {
          background: 'info',
          icon: 'mdi-truck-check-outline',
          iconColor: 'info--text text--lighten-2',
          heading: this.$t('order.delivered'),
          value: 0,
        }]
      },

      async getOrderStatisticsData() {
        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        try {
          const response = await this.getOrderStatistics()
          this.formatOrderData(response.orders)
          this.formatPriceData(response.prices)
        } catch (error) {
          // No need to do anything
        }
        this.setLoading(false)
      },

      formatOrderData(orders) {
        const totalOrderCount = orders.length

        const pendingOrders = orders.filter(
          (order) =>
            order.status === OrderStatusEnum.REQUEST ||
            order.status === OrderStatusEnum.PENDING
        )
        const approvedOrders = orders.filter(
          (order) => order.status === OrderStatusEnum.APPROVED
        )
        const deliveredOrders = orders.filter(
          (order) => order.status === OrderStatusEnum.DELIVERED
        )

        this.orderData[0].value = totalOrderCount
        this.orderData[1].value = pendingOrders.length
        this.orderData[2].value = approvedOrders.length
        this.orderData[3].value = deliveredOrders.length

        const stokabData = orders.filter(
          (order) => order.network && order.network.network === 'STOKAB'
        )
        const ipOnlyData = orders.filter(
          (order) => order.network && order.network.network === 'IP-ONLY'
        )
        const openinfraData = orders.filter(
          (order) => order.network && order.network.network === 'OPENINFRA'
        )
        const openuniverseData = orders.filter(
          (order) => order.network && order.network.network === 'OPENUNIVERSE'
        )
        const sollentunaData = orders.filter(
          (order) => order.network && order.network.network === 'SOLLENTUNA'
        )
        const zitiusData = orders.filter(
          (order) => order.network && order.network.network === 'ZITIUS'
        )

        const stokabDataPercent =
          totalOrderCount &&
          Number((stokabData.length / totalOrderCount) * 100).toFixed(2)
        const ipOnlyDataPercent =
          totalOrderCount &&
          Number((ipOnlyData.length / totalOrderCount) * 100).toFixed(2)
        const openinfraDataPercent =
          totalOrderCount &&
          Number((openinfraData.length / totalOrderCount) * 100).toFixed(2)
        const openuniverseDataPercent =
          totalOrderCount &&
          Number((openuniverseData.length / totalOrderCount) * 100).toFixed(2)
        const sollentunaDataPercent =
          totalOrderCount &&
          Number((sollentunaData.length / totalOrderCount) * 100).toFixed(2)
        const zitiusDataPercent =
          totalOrderCount &&
          Number((zitiusData.length / totalOrderCount) * 100).toFixed(2)

        this.compareData = [{
          category: 'STOKAB',
          count: stokabData.length,
          percent: stokabDataPercent,
          color: 'primary',
        }, {
          category: 'IP-ONLY',
          count: ipOnlyData.length,
          percent: ipOnlyDataPercent,
          color: 'info',
        }, {
          category: 'OPENINFRA',
          count: openinfraData.length,
          percent: openinfraDataPercent,
          color: 'warning',
        }, {
          category: 'OPENUNIVERSE',
          count: openuniverseData.length,
          percent: openuniverseDataPercent,
          color: 'success',
        }, {
          category: 'SOLLENTUNA',
          count: sollentunaData.length,
          percent: sollentunaDataPercent,
          color: 'purple',
        }, {
          category: 'ZITIUS',
          count: zitiusData.length,
          percent: zitiusDataPercent,
          color: 'red',
        }]
      },

      formatPriceData(prices) {
        this.totalPriceCount = prices.length
        const pendingData = prices.filter((price) => {
          if (!price.replyId) {
            return true
          }

          if (this.isPartnerAdminUser) {
            return this.currentUser.id !== price.replyId
          }

          return false
        })

        const pendingCount = pendingData.length
        const approvedCount = this.totalPriceCount - pendingCount

        if (this.totalPriceCount !== 0) {
          this.manualQuotesChartData = [pendingCount, approvedCount]
        }
      },
    }
  }
</script>
